







































































































































import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { mapActions, mapGetters, mapMutations, MutationMethod } from 'vuex';

import BankingProfileTable from '@/components/BankingProfileTable/BankingProfileTable.vue';
import Error from '@/components/fmh_components/common/Error/Error.vue';
import HeadlineEditor from '@/components/fmh_components/common/HeadlineEditor/HeadlineEditor.vue';
import ChildHeader from '@/components/fmh_components/common/ChildHeader/ChildHeader.vue';
import FilterBankingProfile from '@/components/FilterBankingProfile/FilterBankingProfile.vue';

import {
  ADDITIONAL_HEADERS_BANKING_PROFILE,
  ALL_COUNTRIES,
  BASE_BLACK_COLOR,
  DEFAULT_HEADERS_BANKING_PROFILE,
  DEFAULT_SELECTED_HEADERS_BANKING_PROFILE,
  DEFAULT_STR_OPTIONS,
  MODEL_FIELDS,
  WORLD_COUNTRIES,
} from '@/utils/constants';

import { timePeriodOptions, stateValuesBankingProfile } from '@/utils/helpers';
import { FilterParamsData } from '@/interfaces';

interface RequiredPayload {
  title: String;
  config: Object;
  note: String | null;
}

interface UserData {
  isDemoUser: boolean;
  email: string;
  _id: string;
  givenName: string;
  familyName: string;
}

@Component({
  components: {
    ChildHeader,
    Error,
    BankingProfileTable,
    HeadlineEditor,
    FilterBankingProfile,
  },
  computed: {
    ...mapGetters({
      bankingProfile: 'bankingProfile/getBankenProfile',
      isAuthorized: 'auth/isAuthorized',
      error: 'bankingProfile/error',
      loading: 'bankingProfile/loading',
      user: 'auth/getUser',
    }),
  },
  methods: {
    ...mapMutations({
      addNewReport: 'bankingProfile/addNewReport',
      setRowsItems: 'bankingProfile/setRowsItems',
    }),
    ...mapActions({
      createReport: 'bankingProfile/createReport',
      fetchBankingProfile: 'bankingProfile/fetchBankingProfile',
    }),
  },
})
export default class NewReport extends Vue {
  isAuthorized!: Boolean;
  color = BASE_BLACK_COLOR;
  bankingProfile!: Array<Object>;
  rows: Array<Object> = [];
  isShowModal = false;
  isEdit = false;
  isFiltered = false;
  isShowResetButton = false;
  note = '';
  title = '';
  titleRules: any[] = [];
  textActionButtons = {
    confirm: 'save',
    reject: 'cancel',
  };
  user!: UserData;
  modelFields: FilterParamsData = {
    ...MODEL_FIELDS,
    zeitraum: timePeriodOptions().defaultSelected,
  };
  headers: object[] = [...DEFAULT_HEADERS_BANKING_PROFILE].concat(
    DEFAULT_SELECTED_HEADERS_BANKING_PROFILE
  );
  chipsHeaders = ADDITIONAL_HEADERS_BANKING_PROFILE;

  stringOptions = {};
  selectedHeaders: Array<String> = [
    'gesamtkapitalquote',
    'eigenkapitalrendite',
    'schwerpunkte',
    'bankratings',
  ];
  contentWidth = {
    width: '100%',
  };
  addNewReport!: MutationMethod;
  fetchBankingProfile!: () => Promise<Object>;
  createReport!: (payload: RequiredPayload) => Promise<Object>;

  @Watch('bankingProfile', { immediate: true }) setValues(val) {
    const rowsItems = JSON.parse(localStorage.getItem('rowsItems') || '[]');
    const filterParams = JSON.parse(
      localStorage.getItem('filterParams') || '{}'
    );
    const bpHeaders = JSON.parse(
      localStorage.getItem('bpHeaders') ||
        '{"headers": [], "selectedHeaders": []}'
    );
    const savedOptions = JSON.parse(
      localStorage.getItem('stringOptions') || '{}'
    );
    const allCountries = ALL_COUNTRIES.sort((a, b) => {
      if (
        (a.toLowerCase() || b.toLowerCase()) === 'deutschland' ||
        (a.toLowerCase() || b.toLowerCase()) === 'austria'
      ) {
        return -1;
      }
      return (a as any).MylocaleCompare(b);
    });
    if (!Object.keys(savedOptions).length) {
      const payload = {
        standortlaender: allCountries,
        schwerpunkte: DEFAULT_STR_OPTIONS.schwerpunkte,
      };
      this.setDefaultParams(payload);
      localStorage.setItem('stringOptions', JSON.stringify(payload));
    }
    if (!Object.keys(filterParams).length) {
      localStorage.setItem('filterParams', JSON.stringify(this.modelFields));
    }
    if (Object.keys(filterParams).length) {
      this.modelFields = filterParams;
    }
    if (val.length && !rowsItems.length) {
      const { stringOptions } = stateValuesBankingProfile(
        this.headers,
        this.chipsHeaders,
        val
      );
      Object.assign(stringOptions, { standortlaender: allCountries });
      if (savedOptions?.standortlaender?.length) {
        const { region, standort } = filterParams;
        this.modelFields = {
          ...this.modelFields,
          region: region,
          standort: standort,
        };
        this.stringOptions = {
          ...stringOptions,
          standortlaender: savedOptions?.standortlaender || [],
        };
      }
      if (!savedOptions?.standortlaender?.length) {
        this.setDefaultParams(stringOptions);
        localStorage.setItem(
          'stringOptions',
          JSON.stringify(this.stringOptions)
        );
        localStorage.setItem('filterParams', JSON.stringify(this.modelFields));
      }
      this.rows = this.bankingProfile;
    }
    setTimeout(() => {
      if (bpHeaders.headers.length) {
        this.headers = bpHeaders.headers;
        this.selectedHeaders = bpHeaders.selectedHeaders;
      }
      if (!bpHeaders.headers.length) {
        localStorage.setItem(
          'bpHeaders',
          JSON.stringify({
            headers: this.headers,
            selectedHeaders: this.selectedHeaders,
          })
        );
      }

      if (Object.keys(filterParams).some((key) => filterParams[key].length)) {
        this.modelFields = filterParams;
      }
      if (rowsItems.length) {
        this.rows = rowsItems;
        const { region, standort } = filterParams;
        this.modelFields = {
          ...this.modelFields,
          region: region,
          standort: standort,
        };
        this.stringOptions = savedOptions;
      }
      this.validateResetButton();
    }, 200);
    this.validateResetButton();
    this.setTableWidth(false);
  }
  @Watch('isAuthorized') getData(val) {
    if (val && !this.bankingProfile.length) {
      this.fetchBankingProfile();
    }
  }

  get validateUser() {
    return this.user?.isDemoUser || false;
  }
  get info() {
    return 'In der Demoversion werden nur 10 Banken von 257 angezeigt.';
  }
  get width() {
    return this.contentWidth;
  }
  setDefaultParams(stringOptions) {
    this.stringOptions = stringOptions;
  }
  currentCountries(arr) {
    const allCurrentCountries: string[] = [];
    arr.forEach((item) => {
      for (let i = 0; i < WORLD_COUNTRIES[item].length; i += 1) {
        if (!allCurrentCountries.includes(WORLD_COUNTRIES[item][i])) {
          allCurrentCountries.push(WORLD_COUNTRIES[item][i]);
        }
      }
    });
    return allCurrentCountries;
  }
  handleChangeRegion(arr) {
    const allCurrentCountries = this.currentCountries(arr);
    Object.assign(this.modelFields, {
      region: arr,
      standort: allCurrentCountries,
    });
  }
  handleChangeAllCountriesSelected(val) {
    const regions = [
      'dach',
      'europa',
      'eu',
      'benelux',
      'brics',
      'afrika',
      'asien',
      'australien und ozeanien',
      'amerikas',
    ];
    const allCurrentCountries = this.currentCountries(regions);
    if (val) {
      this.modelFields = {
        ...this.modelFields,
        standort: allCurrentCountries,
        region: regions,
      };
    } else {
      this.modelFields = {
        ...this.modelFields,
        standort: [],
        schwerpunkte: [],
        region: [],
      };
    }
  }
  handleChangeSelectedHeaders(val) {
    this.selectedHeaders = val;
  }
  handleChangeStockExchangeListed(val) {
    this.modelFields = { ...this.modelFields, boersennotiert: val };
  }
  handleChangeKeyAreas(val) {
    this.modelFields = { ...this.modelFields, schwerpunkte: val };
  }
  handleChangeLocationCountries(values) {
    if (!values.length) {
      this.modelFields = { ...this.modelFields, standort: [], region: [] };
    } else {
      const current = {};
      const region: string[] = [];

      this.modelFields.region.forEach((key) => {
        const arr: string[] = [];
        WORLD_COUNTRIES[key].forEach((item) => {
          if (!arr.includes(item) && values.includes(item)) {
            arr.push(item);
          }
          Object.assign(current, { [key]: arr });
        });
      });
      Object.keys(current).forEach((key) => {
        if (current[key].length === WORLD_COUNTRIES[key].length) {
          region.push(key);
        }
      });
      this.modelFields = { ...this.modelFields, standort: values, region };
    }
  }
  handleChangePeriod(val) {
    this.modelFields = { ...this.modelFields, zeitraum: val };
  }
  onHeadersChanged(val) {
    this.headers = val;
    this.setTableWidth(false);
  }
  onRowsChanged(val) {
    this.rows = val;
    const bpHeaders = JSON.stringify({
      headers: this.headers,
      selectedHeaders: this.selectedHeaders,
    });
    localStorage.setItem('bpHeaders', bpHeaders);
    localStorage.setItem('rowsItems', JSON.stringify(val));
    localStorage.setItem('filterParams', JSON.stringify(this.modelFields));
    localStorage.setItem('stringOptions', JSON.stringify(this.stringOptions));
    this.validateResetButton();
  }
  onLoadingChanged(val) {
    this.contentWidth = {
      width: '100%',
    };
    this.isFiltered = val;
  }
  toggleAll(val) {
    const result: string[] = [];
    if (val) {
      for (let i = 0; i < this.chipsHeaders.length; i += 1) {
        result.push(this.chipsHeaders[i].value);
      }
      this.selectedHeaders = result;
    } else {
      this.selectedHeaders = [];
    }
  }
  setTableWidth(reset: boolean) {
    setTimeout(() => {
      const windowWidth = window.innerWidth - 410;
      const contentWidth = (this.$refs as any)?.table?.$el?.firstChild
        ?.children?.[0]?.scrollWidth;
      if (reset) {
        this.contentWidth = {
          width: `${windowWidth}px`,
        };
      }
      if (contentWidth > windowWidth && !reset && this.selectedHeaders.length) {
        this.contentWidth = {
          width: `${contentWidth}px`,
        };
      } else {
        this.contentWidth = {
          width: '100%',
        };
      }
    }, 500);
  }
  validateResetButton() {
    if (this.user?.isDemoUser) {
      return true;
    }
    const arr: string[] = [];
    Object.keys(this.modelFields).forEach((key) => {
      if (key === 'zeitraum' && this.modelFields[key].length !== 1) {
        arr.push(key);
      }
      if (
        key !== 'zeitraum' &&
        typeof this.modelFields?.[key] === 'boolean' &&
        this.modelFields?.[key]
      ) {
        arr.push(key);
      }
      if (
        key !== 'zeitraum' &&
        typeof this.modelFields?.[key] !== 'boolean' &&
        this.modelFields?.[key]?.length
      ) {
        arr.push(key);
      }
    });
    this.isShowResetButton = !!arr.length;
  }
  resetFilters() {
    this.modelFields = {
      ...MODEL_FIELDS,
      zeitraum: timePeriodOptions().defaultSelected,
    };
    const stringOptions = JSON.parse(
      localStorage.getItem('stringOptions') || '{}'
    );

    this.setDefaultParams(stringOptions);
    this.headers = [...DEFAULT_HEADERS_BANKING_PROFILE].concat(
      DEFAULT_SELECTED_HEADERS_BANKING_PROFILE
    );
    this.selectedHeaders = [
      'gesamtkapitalquote',
      'eigenkapitalrendite',
      'schwerpunkte',
      'bankratings',
    ];
    this.rows = JSON.parse(localStorage.getItem('defaultFilteredRows') || '[]');
    this.validateResetButton();
    this.setTableWidth(true);
    localStorage.setItem('filterParams', JSON.stringify(this.modelFields));
    localStorage.removeItem('bpHeaders');
    localStorage.removeItem('rowsItems');
  }
  handleConfirm() {
    this.titleRules = [(v) => !!v || 'Titel ist erforderlich'];
    setTimeout(async () => {
      if ((this.$refs.form as any).validate()) {
        this.isEdit = true;
        const savedOptions = JSON.parse(
          localStorage.getItem('stringOptions') || ''
        );
        const config = {
          stringOptions: savedOptions || this.stringOptions,
          selectedHeaders: this.selectedHeaders,
          modelFields: this.modelFields,
          total: this.rows.length,
        };
        const response = await this.createReport({
          config,
          title: this.title,
          note: this.note || null,
        });
        this.addNewReport((response as any).data);
        this.isEdit = false;
        this.title = '';
        this.titleRules = [];
        return this.$router.push('/banking-profile');
      }
    });
  }
  onDialogChanged(val) {
    this.isShowModal = val;
  }

  created() {
    if (this.isAuthorized && !this.bankingProfile.length) {
      this.fetchBankingProfile();
    }
  }
}
