





































import { Vue, Prop, Component } from 'vue-property-decorator';

import { COLOR } from '@/utils/constants';

@Component({})
export default class HeadlineEditor extends Vue {
  @Prop() title!: String;
  @Prop() isShowModal!: Boolean;
  @Prop() text: String | undefined;
  @Prop() currentHeadline!: String;
  @Prop() textActionButtons!: Object;
  @Prop() isEdit!: Boolean;
  @Prop() confirmAction!: Function;

  headline = '';
  color = COLOR;

  get model() {
    return this.isShowModal;
  }

  set model(value) {
    this.$emit('change', value);
  }

  handleChangeDialog(val) {
    this.$emit('change', val);
  }
}
